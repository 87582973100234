import React from 'react'
import { graphql } from 'gatsby'
import PageHeader from '../components/PageHeader'
import Contact from '../components/Forms/Contact/Contact'
import Content from '../components/Content'
import Layout from '../components/Layout'
import './ContactPage.css'
//import WistiaVideo from '../components/WistiaVideo/WistiaVideo';
//import { MapPin, Smartphone, Mail } from 'react-feather'
//import GoogleMap from '../components/GoogleMap'

export const ContactPageTemplate = ({
  body,
  title,
  subtitle,
  featuredImage,
  formText
}) => (
    <main className="Contact">
      <PageHeader
        title={title}
        subtitle={subtitle}
        backgroundImage={featuredImage}
      />
      <section className="section Contact--Section1">
        <div className="container">
          <h1>
            <Content source={title} />
          </h1>
          <div>
            <Content source={body} />
            {/* This might be useful in the future */}
            {/* <div className="Contact--Details">
              {address && (
                <a
                  className="Contact--Details--Item"
                  href={`https://www.google.com.au/maps/search/${encodeURI(
                    address
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MapPin /> {address}
                </a>
              )}
              {phone && (
                <a className="Contact--Details--Item" href={`tel:${phone}`}>
                  <Smartphone /> {phone}
                </a>
              )}
              {email && (
                <a className="Contact--Details--Item" href={`mailto:${email}`}>
                  <Mail /> {email}
                </a>
              )}
            </div> */}
          </div>
        </div>
      </section>
      <section className="section Video--Container">
        <div className="container">
          {/* <WistiaVideo
            placeholderImage="https://fast.wistia.com/embed/medias/vjzszvgfsm/swatch"
            wistiaId="vjzszvgfsm"
          /> */}
          <div className="Iframe">
            <iframe title="contact video" width="560" height="315" src="https://www.youtube.com/embed/qPpCsZ_3mGs?rel=0" frameborder="0" rel="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="Contact--FormArea">
            <Content source={formText} />
          </div>
          <Contact name="Contact Us" />
        </div>
      </section>

      {/* <GoogleMap locations={locations} /> */}
    </main>
  )

const ContactPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <ContactPageTemplate {...page.frontmatter} body={page.html} />
  </Layout>
)

export default ContactPage

export const pageQuery = graphql`
  query ContactPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        template
        subtitle
        featuredImage
        formText
      }
    }
  }
`
