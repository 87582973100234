import React, { Component } from "react"
import '../Form.css'
import './Contact.css'

export default class FileUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonLabel: "Submit",
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }


  componentDidMount() {
    //
  }



  handleSubmit(event) {
    if (document.getElementById("_gotcha").value !== "") return false;
    this.setState({ buttonLabel: "...Sending..." })
    const name = document.getElementById("Form--Name").value
    const email = document.getElementById("Form--Email").value
    const message = document.getElementById("Form--Message").value

    event.preventDefault();

    fetch('/api/email', {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        to: process.env.GATSBY_EMAIL_TO,
        subject: "Weave Connected Contact Form",
        textBody: "\r\n name: " + name + "\r\n email: " + email + "\r\n message: " + message,
        emailBody: "<br><strong> name:</strong> " + name + "<br><strong> email:</strong> " + email + "<br><strong> message: </strong>" + message,
      })
    }).then(done => this.setState({ buttonLabel: "Thank You! Message Received!" }))
  }

  render() {
    const name = "Contact Form"
    return (
      <form onSubmit={this.handleSubmit}
        className="Form"
        name={name}
      >

        <div className="Flex--Row">

          <label className="Form--Label Input--Half">
            <input
              className="Form--Input Input--Name"
              type="text"
              placeholder="Name"
              name="name"
              id="Form--Name"
              required
            />
          </label>
          <label className="Form--Label Input--Half">
            <input
              className="Form--Input Input--Email"
              type="text"
              placeholder="Email"
              name="email"
              id="Form--Email"
              required
            />
          </label>
        </div>
        <label className="Form--Message" id="Form--Message-Label">
          <span className="Form--Label-Text">
            {this.state.uploadLabel}
          </span>
          <textarea
            rows="8"
            id="Form--Message"
            placeholder="Your Message"
            className="Form--Input Form--Message"
            required
          />
        </label>

        <input type="text" name="_gotcha" id="_gotcha" style={{ display: 'none' }} placeholder="your name" />

        <button className="Button Form--Submit-Button" type="submit" id="SubmitButton">{this.state.buttonLabel}</button>

      </form>
    );
  }
}
